import axios from 'axios'
import authHeader from './auth-header'
import { admins } from '@/store/admins.module'

const API_URL = process.env.VUE_APP_URL

class AdminService {
  getAdminsList (id: string) {
    return axios
      .get(API_URL + 'super-admin/admins' + id, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getSchoolList (id: string) {
    return axios
      .get(API_URL + 'super-admin/schools' + id, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getSchool (id: string) {
    return axios
      .get(API_URL + 'super-admin/schools/' + id, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getAdmin (id: string) {
    return axios
      .get(API_URL + 'super-admin/admins/' + id, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteAdmin (id: string) {
    return axios
      .delete(API_URL + 'super-admin/admins/' + id, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteSchool (id: string) {
    return axios
      .delete(API_URL + 'super-admin/schools/' + id, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  generateInviteCode () {
    return axios
      .get(API_URL + 'super-admin/generate-code', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getPrograms () {
    return axios
      .get(API_URL + 'admin/programs', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  impersonateSchool (id: string) {
    return axios
      .post(API_URL + 'super-admin/schools/' + id + '/impersonate', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  adminMySchoolUpdate (data: any) {
    return axios
      .post(API_URL + 'admin/my-school/' + data.id + '/update', { free_trial_days_interval: data.free_trial_days_interval, code: data.code }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}

export default new AdminService()
