import AdminService from '@/services/admins.service'
import router from '@/router'

const admin: [] = JSON.parse(localStorage.getItem('admin-sra') || '{}')
const initialState = admin
  ? { status: { loggedIn: true }, admin }
  : { status: { loggedIn: false }, admin: null }

export const admins = {
  namespaced: true,
  state: initialState,
  actions: {
    getAdminsList ({ commit }: any, id: string) {
      return AdminService.getAdminsList(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getSchoolList ({ commit }: any, id: string) {
      return AdminService.getSchoolList(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getSchool ({ commit }: any, id: string) {
      return AdminService.getSchool(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getAdmin ({ commit }: any, id: string) {
      return AdminService.getAdmin(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    deleteAdmin ({ commit }: any, id: string) {
      return AdminService.deleteAdmin(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    deleteSchool ({ commit }: any, id: string) {
      return AdminService.deleteSchool(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    generateInviteCode ({ commit }: any) {
      return AdminService.generateInviteCode().then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getPrograms ({ commit }: any) {
      return AdminService.getPrograms().then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    impersonateSchool ({ commit }: any, id: string) {
      return AdminService.impersonateSchool(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    adminMySchoolUpdate ({ commit }: any, data: any) {
      return AdminService.adminMySchoolUpdate(data).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
  }
}
