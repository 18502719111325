
import { defineComponent } from 'vue'
import Logo from '@/assets/svg/logo.svg?inline'
import Copy from '@/assets/svg/copy.svg?inline'
import MobileAlert from '@/assets/svg/mobile-alert.svg?inline'

export default defineComponent({
  name: 'Header',
  data () {
    return {
      mobileMenu: false,
      errorLogout: null,
      email: '',
      school: {},
      role: 'admin',
      copied: false,
      schoolCode: null
    }
  },
  components: {
    Logo,
    Copy,
    MobileAlert
  },
  computed: {
    loggedIn () {
      return this.$store.state.auth.status.loggedIn
    }
  },
  mounted () {
    this.email = JSON.parse(localStorage.getItem('admin-sra-name'))
    this.school = JSON.parse(localStorage.getItem('sra-school'))
    this.schoolCode = JSON.parse(localStorage.getItem('school-code'))
    this.role = JSON.parse(localStorage.getItem('sra-roles'))[0]
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout').then(
        () => {
          this.$router.push('/')
        },
        (error) => {
          this.errorLogout = error.response.data.errors
        }
      )
    },
    returnToSa () {
      this.$router.push('/super-admin/school-list')
    },
    copyText () {
      let textToCopy = this.school[0].code
      if (this.schoolCode !== null) {
        textToCopy = this.schoolCode
      }
      const textarea = document.createElement('textarea')
      textarea.value = textToCopy
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
      this.copied = true

      setTimeout(() => {
        this.copied = false
      }, 3000)
    }
  }
})
