import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__mobile-alert" }
const _hoisted_3 = { class: "header__school-wrap" }
const _hoisted_4 = {
  key: 0,
  class: "header__school-wrpa"
}
const _hoisted_5 = { class: "header__school-container" }
const _hoisted_6 = { class: "header__school-name" }
const _hoisted_7 = {
  key: 0,
  class: "header__school-container"
}
const _hoisted_8 = {
  key: 1,
  class: "header__school-container"
}
const _hoisted_9 = { class: "header__right-block" }
const _hoisted_10 = { class: "header__email" }
const _hoisted_11 = {
  key: 0,
  class: "header__copied"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileAlert = _resolveComponent("MobileAlert")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Copy = _resolveComponent("Copy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MobileAlert),
      _cache[2] || (_cache[2] = _createTextVNode(" Please turn the screen over "))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_link, {
        to: "/admin/instructors",
        class: "header__logo"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Logo)
        ]),
        _: 1
      }),
      (this.role === 'admin' && _ctx.loggedIn)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.school, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(item.title), 1),
                  (this.schoolCode === null)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("span", null, _toDisplayString(item.code), 1),
                        (item.code)
                          ? (_openBlock(), _createBlock(_component_Copy, {
                              key: 0,
                              onClick: _ctx.copyText
                            }, null, 8, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("span", null, _toDisplayString(this.schoolCode), 1),
                        (item.code)
                          ? (_openBlock(), _createBlock(_component_Copy, {
                              key: 0,
                              onClick: _ctx.copyText
                            }, null, 8, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]))
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, _toDisplayString(this.email), 1),
      (this.role === 'super_admin' && !_ctx.$route.path.includes('super-admin'))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "header__return-to-sa",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (this.returnToSa()))
          }, " Return to SA "))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "header__logout",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (this.logout()))
      }, " Log out ")
    ]),
    (_ctx.copied)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, " Code copied! "))
      : _createCommentVNode("", true)
  ]))
}